import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import bank1 from "../../images/group_bw.png";
import closeIcon from "../../images/close.png";

import { DEMO_FORM } from "../../modules/constants";
import Button from "../Button";
import Dropdown from "../Dropdown";
import { navigate } from "gatsby";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import TextInput from "../TextInput";
import BlunoSlider from "../BlunoSlider";
import { DeviceContext } from "../../context/DeviceContext";

const DemoClone = ({ setScreen, setOpenModal, flowType = "home_page" }) => {
  const data = DEMO_FORM;

  const isMobileDevice = useContext(DeviceContext);

  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [currency, setCurrency] = useState("inr");
  const [submitError, setSubmitError] = useState("");

  const getValueText = (value) => {
    if (currency === "inr") {
      if (Math.round(value) != value) value = value.toFixed(2);

      if (value !== 1 && value !== 500) return `${value} Cr`;
      else if (value === 1) return `<${value} Cr`;
      else return `>${value} Cr`;
    } else {
      if (value === 100) {
        return `<${value}K`;
      }
      if (value === 50000) {
        return `>${value / 1000}M`;
      }
      if (value < 1000) {
        return `${value}K`;
      } else {
        return `${value / 1000}M`;
      }
    }
  };
  const calculateScale = (value) => {
    if (currency === "inr") {
      if (value >= 0 && value < 100) {
        return 1 + (value * 4) / 100;
      } else if (value >= 100 && value < 200) {
        return 5 + ((value - 100) * 20) / 100;
      } else if (value >= 200 && value < 300) {
        return 25 + ((value - 200) * 25) / 100;
      } else if (value >= 300 && value < 400) {
        return 50 + ((value - 300) * 50) / 100;
      } else if (value >= 400 && value <= 500) {
        return 100 + ((value - 400) * 400) / 100;
      }
    } else {
      if (value >= 0 && value < 100) {
        return 100 + (value * 400) / 100;
      } else if (value >= 100 && value < 200) {
        return 500 + ((value - 100) * 500) / 100;
      } else if (value >= 200 && value < 300) {
        return 1000 + ((value - 200) * 4000) / 100;
      } else if (value >= 300 && value < 400) {
        return 5000 + ((value - 300) * 20000) / 100;
      } else if (value >= 400 && value <= 500) {
        return 25000 + ((value - 400) * 25000) / 100;
      }
    }
  };
  const getMarks = (marks) => {
    return marks.map((item, idx) => {
      return {
        value: item?.value,
        label: (
          <div
            style={{
              textAlign:
                idx === 0
                  ? "left"
                  : idx === marks.length - 1
                  ? "right"
                  : "center",
              width: idx === marks.length - 1 ? "54px" : "",
              marginLeft:
                idx === marks.length - 1 ? "-50px" : idx === 0 ? "34px" : "",
            }}
          >
            {item?.text1}
            <br />
            {item?.text2}
          </div>
        ),
      };
    });
  };

  const handleInputChange = (slug, value, sub_type, e) => {
    if (sub_type === "tel") {
      // Phone number validation
      const isValidPhoneNumber = /^\d{0,10}$/.test(value);
      if (!isValidPhoneNumber) {
        if (value.toString().length > 10)
          setErrors({
            ...errors,
            [slug]: "You have entered 10 digits",
          });
        else
          setErrors({
            ...errors,
            [slug]: "Enter correct mobile number",
          });
        // e.target.value = formValues?.[slug];
      } else {
        setErrors({ ...errors, [slug]: null });
        setFormValues({ ...formValues, [slug]: value });
      }
    } else {
      setErrors({ ...errors, [slug]: null });
      setFormValues({ ...formValues, [slug]: value });
    }
  };

  const handleOnFocusOut = (slug, value, sub_type, e) => {
    if (sub_type === "email" && value.trim() !== "") {
      // Email validation
      const isValidEmail =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value.trim());
      if (!isValidEmail) {
        setErrors({ ...errors, [slug]: "Enter a valid email address" });
      } else {
        setErrors({ ...errors, [slug]: null });
      }
    } else if (sub_type === "tel" && value.trim() !== "") {
      // Phone number validation
      const isValidPhoneNumber = /^\d{10}$/.test(value);
      if (!isValidPhoneNumber) {
        if (value.toString().length > 10)
          setErrors({
            ...errors,
            [slug]: "You have entered 10 digits",
          });
        else
          setErrors({
            ...errors,
            [slug]: "Enter correct mobile number",
          });
      } else {
        setErrors({ ...errors, [slug]: null });
      }
    }
  };
  const handleSelectChange = (selectedOption, slug) => {
    setCurrency(selectedOption.value);
    setFormValues({ ...formValues, [slug]: selectedOption.value });
  };
  const handleCheckBoxClick = (parentSlug, childSlug, theValue) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [parentSlug]: {
        ...prevFormValues[parentSlug],
        [childSlug]: theValue,
      },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    data.form_data.forEach((item) => {
      const value = formValues[item?.slug];
      if (!value && item.type === "input") {
        newErrors[item?.slug] = "This field is required";
        isValid = false;
      } else if (
        item.type === "input" &&
        (item.sub_type === "email" || item.sub_type === "tel")
      ) {
        if (value) {
          if (item.sub_type === "email") {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
              value.trim()
            );
            if (!isValidEmail) {
              newErrors[item?.slug] = "Please enter a valid email address";
              isValid = false;
            }
          } else if (item.sub_type === "tel") {
            const isValidPhoneNumber = /^\d{10}$/.test(value);
            if (!isValidPhoneNumber) {
              newErrors[item?.slug] = "Please enter a 10-digit phone number";
              isValid = false;
            }
          }
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmitForm = async () => {
    let tempFormValue = JSON.parse(JSON.stringify(formValues));
    tempFormValue.email_address = tempFormValue.email_address.trim();
    const requestBody = {
      demo_type: "callback_request",
      form_data: tempFormValue,
      scheduling_data: null,
    };

    //TODO: CHANGE URL FOR PROD
    await axios
      .post("https://demo.bluno.in/v1/bluno/book-demo", requestBody)
      .then((response) => {
        if (response?.data?.success) {
          setScreen(2);
        }
      })
      .catch((error) => {
        if (error?.response && error?.response?.data) {
          setSubmitError(error?.response?.data?.message);
        }
      });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleSubmitForm();
    }
  };

  const handleCloseClick = () => {
    if (flowType === "home_page") {
      setOpenModal(false);
    } else {
      //TODO: change url for prod and dev
      window.parent.postMessage("close", "https://bluno.in/content/");
    }
  };

  const renderFormElement = (obj) => {
    switch (obj?.type) {
      case "input":
        return (
          <div
            style={{
              gridColumn: `${obj?.column}`,
              gridRow: `${obj?.row}`,
              display: "flex",
            }}
            className="s-demo__group-text-input"
          >
            <TextInput
              placeholder={obj?.placeholder}
              value={formValues[obj?.slug]}
              showMuiInput={true}
              height="38px"
              minWidth="225px"
              iconAlignment="right"
              onChange={(e) =>
                handleInputChange(obj?.slug, e.target.value, obj?.sub_type, e)
              }
              onBlur={(e) =>
                handleOnFocusOut(obj?.slug, e.target.value, obj?.sub_type, e)
              }
              errorMessage={errors[obj?.slug]}
              searchIcon={false}
              fixedText={obj?.sub_type === "tel" ? "+91" : ""}
            />
          </div>
        );
      case "checkbox":
        return (
          <div
            className="s-demo__group-checkbox"
            style={{
              gridRow: `${obj?.row}`,
              gridColumn: `${obj?.column}`,
              display: "grid",
            }}
          >
            <p
              className="s-demo__label"
              dangerouslySetInnerHTML={sanitizeHtml(obj?.label)}
            ></p>
            <div className="s-demo__radio-list">
              {obj?.options?.map((item, idx) => {
                return (
                  <div key={idx} className="s-demo__radio">
                    <input
                      type="checkbox"
                      name={item?.name}
                      id={`${item?.name}_${idx}`}
                      value={item?.slug}
                      onChange={(e) =>
                        handleCheckBoxClick(
                          obj?.slug,
                          item?.slug,
                          e.target.checked
                        )
                      }
                      key={item?.slug + idx}
                    />
                    <label htmlFor={`${item?.name}_${idx}`}>{item?.text}</label>
                  </div>
                );
              })}
            </div>
            {errors[obj?.slug] && (
              <p className="s-demo__error">{errors[obj?.slug]}</p>
            )}
          </div>
        );
      case "dropdown":
        return (
          <div
            className="s-demo__group-dropdown"
            style={{
              gridRow: `${obj?.row}`,
              gridColumn: `${obj?.column}`,
              display: "grid",
            }}
          >
            <div className="s-demo__dropdown-title">
              <div className="s-demo__dropdown-cont">
                <Dropdown
                  options={obj?.options}
                  onSelectChange={(selectedOption) => {
                    handleSelectChange(selectedOption, obj?.slug);
                  }}
                  defaultValue={formValues[obj?.slug]}
                  placeholder="INR"
                  fontSize="13px"
                  fontWeight="400"
                  valuePadding="0px"
                  indicatorPadding="0px"
                  minHeight="31px"
                />
              </div>
              <p
                className="s-demo__label"
                dangerouslySetInnerHTML={sanitizeHtml(obj?.label)}
              ></p>
            </div>
            {errors[obj?.slug] && (
              <p className="s-demo__error-dropdown">{errors[obj?.slug]}</p>
            )}
          </div>
        );
      case "slider":
        return (
          <div
            style={{
              gridRow: `${obj?.row}`,
              display: "grid",
              gridColumn: `${obj?.column}`,
            }}
            className="s-demo__slider"
          >
            <BlunoSlider
              step={obj?.step}
              min={obj?.min}
              max={obj?.max}
              marks={
                currency === "inr"
                  ? getMarks(obj?.marks)
                  : getMarks(obj?.marksUSD)
              }
              valueLabelDisplay={"auto"}
              scale={calculateScale}
              getAriaValueText={getValueText}
              valueLabelFormat={getValueText}
              onChange={(e) => {
                const value = getValueText(calculateScale(e.target.value));
                handleInputChange(obj?.slug, value, null, e);
              }}
            />
          </div>
        );
      case "button":
        return (
          <div
            style={{
              gridRow: `${obj?.row}`,
              gridColumn: `${obj?.column}`,
            }}
            className="s-demo__button"
          >
            <Button
              buttonLabel={obj?.button_label}
              buttonSize="md"
              icon={obj?.icon}
              iconAlignment={obj?.iconAlignment}
              onClick={() => {
                handleSubmit();
              }}
              textSize="13px"
            />
            {submitError && <p className="s-demo__error">{submitError}</p>}
          </div>
        );
      case "text":
        return (
          <div
            style={{
              gridColumn: `${obj?.column}`,
              gridRow: `${obj?.row}`,
              display: "flex",
            }}
            className="s-demo__group-text"
          >
            <p
              dangerouslySetInnerHTML={sanitizeHtml(obj?.text, {
                ADD_ATTR: ["target"],
              })}
              className="s-demo__agreement"
            ></p>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const initialFormValues = {
      export_turnover_currency: "inr",
      export_type: {
        good_and_merchandise: false,
        services: false,
      },
    };

    setFormValues(initialFormValues);

    Array.from(document?.getElementsByClassName("MuiSlider-markLabel"))?.map(
      (item, idx) => {
        if (item?.style?.left === "0.4%") {
          item.style.marginLeft = "-10px";
        }
        if (item?.style?.left === "98.6%") {
          item.style.marginLeft = "10px";
        }
      }
    );
  }, []);

  return (
    <div className="s-demo">
      <div className="s-demo__left">
        <div className="s-demo__left-top">
          <img src="https://static.bluno.in/landing/security-2.png" alt="" />
          <p>100% safe and secure</p>
        </div>
        <img
          className="s-demo__banner"
          src="https://static.bluno.in/landing/demo_picture.png"
          alt=""
        />
        {/* <div className="s-demo__left-bottom">
          <p>Powered by</p>
          <div className="s-demo__img-list">
            <img src={bank1} alt="" />
          </div>
        </div> */}
      </div>
      <div className="s-demo__right">
        <div onClick={() => handleCloseClick()} className="s-demo__close">
          {isMobileDevice && <p>Close</p>}
          <img src={closeIcon} alt="close" />
        </div>
        <div className="s-demo__form-cont">
          <h1
            className="s-demo__title"
            dangerouslySetInnerHTML={{ __html: data?.title }}
          />
          <div className="s-demo__form">
            {data?.form_data?.map((obj, idx) => {
              return renderFormElement(obj);
            })}
          </div>
          {isMobileDevice && (
            <div className="s-demo__left-top">
              <img
                src="https://static.bluno.in/landing/security-2.png"
                alt=""
              />
              <p>100% safe and secure</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemoClone;
