import React from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
export default function BlunoSlider({
  step,
  min,
  max,
  marks,
  valueLabelDisplay,
  getAriaValueText,
  valueLabelFormat,
  scale,
  onChange,
}) {
  function blunoThumb(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <span className="bluno-bar" />
      </SliderThumb>
    );
  }
  return (
    <>
      <Slider
        step={step}
        min={min}
        max={max}
        marks={marks}
        slots={{ thumb: blunoThumb }}
        valueLabelDisplay={valueLabelDisplay}
        getAriaValueText={getAriaValueText}
        valueLabelFormat={valueLabelFormat}
        scale={scale}
        onChange={onChange}
        sx={{
          ".MuiSlider-markLabel": {
            wordWrap: "break-word",
            whiteSpace: "normal",
            color: "#1F1F1F",
            fontSize: "10px",
          },
          ".MuiSlider-rail": {
            height: "14px",
            backgroundColor: "#D9D9D9",
            borderRadius: "5px",
          },
          ".MuiSlider-track": {
            height: "10px",
            borderRadius: "5px",
            backgroundColor: "#046CB8",
          },
          ".MuiSlider-mark": {
            height: "7px",
            width: "7px",
            borderRadius: "7px",
            backgroundColor: "#4F4F4F",
          },
          ".MuiSlider-markActive": {
            backgroundColor: "#D9D9D9",
          },
          ".MuiSlider-thumb": {
            width: "15px",
            height: "26px",
            borderRadius: "5px",
            backgroundColor: "#EFEEEE",
            boxShadow: "0px 0px",
            border: "1px solid #D1D1D1",
            "&:hover, &:focus, &:before,&:after, &.Mui-active,&.Mui-focusVisible":
              {
                boxShadow: "0px 0px",
              },
          },
          ".bluno-bar": {
            height: "15px",
            border: "1px solid #D1D1D1",
            borderRadius: "10px",
          },
        }}
      />
    </>
  );
}
