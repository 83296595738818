import React from "react";
import DOMPurify from "dompurify";

export const sanitizeHtml = (theHtml, attr) => {
  if (!theHtml) return null;

  const sanitizedHtmlContent = DOMPurify.sanitize(theHtml, attr);

  return { __html: sanitizedHtmlContent };
};
