// react imports
import React from "react";

// third party imports

// in-app imports
import "./src/styles/styles.scss";
import { DeviceProvider } from "./src/context/DeviceContext";

import Layout from "./src/components/Layout";

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("new pathname", location.pathname);
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null);
};

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <DeviceProvider>
      <Layout {...props}>{element}</Layout>
    </DeviceProvider>
  );
};
