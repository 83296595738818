import React, { useEffect } from "react";

const BlunoModal = (props) => {
  const { children, openModal, setOpenModal, screen, setScreen, closeOutside } =
    props;

  useEffect(() => {
    if (openModal) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = "fixed";
      document.body.style.left = "0px";
      document.body.style.right = "0px";
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.left = "";
      document.body.style.right = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [openModal]);
  return (
    <>
      {openModal && (
        <div
          className="s-modal"
          onClick={(e) => {
            if (e.target === e.currentTarget && closeOutside) {
              setOpenModal(false);
            }
          }}
        >
          <div className="s-modal__child">
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                openModal,
                setOpenModal,
                screen,
                setScreen,
              })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BlunoModal;
