import React from "react";

const InputErrorMsg = ({
  message = "",
  icon = "",
  type = "error",
  textAlignment = "left",
  iconAlignment = "right",
  extraClass = "",
}) => {
  return (
    <>
      <div
        className={`g-error-msg g-error-msg--${type} g-error-msg--icon--${iconAlignment} g-error-msg--${textAlignment} ${extraClass}`}
      >
        <p className="g-error-msg__text">{message}</p>
        {icon && <img src={icon} alt="" className="g-error-msg__icon" />}
      </div>
    </>
  );
};

export default InputErrorMsg;
