import React, { createContext, useState, useEffect } from "react";
import {
  isMobile,
  isTablet,
  isDesktop,
  deviceDetect,
} from "react-device-detect";

const DeviceContext = createContext();

const DeviceProvider = ({ children }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile);
  }, [isMobile]);

  return (
    <DeviceContext.Provider value={isMobileDevice}>
      {children}
    </DeviceContext.Provider>
  );
};

export { DeviceContext, DeviceProvider };
