// in-app imports

// About-us
import randomGif from "../images/RandomGif.gif";

// Banner
// import icici from "../images/demo-bank-color-2.png";
// import ca from "../images/power-2.png";
import yes from "../images/group_color.png";

// Empower
import geteBRC from "../images/Empower/get_eBRC.png";
import realTime from "../images/Empower/real_time.png";
import boostMargin from "../images/Empower/boost_margin.png";

// Testimonial
import user1 from "../images/testimonials/User1.png";
import user2 from "../images/testimonials/User2.png";
import user3 from "../images/testimonials/User3.png";

// Thank you
import handshake from "../images/handshake.png";
import BlunoIcon from "../images/blunoIcon.png";

// Footer
import blunologo from "../images/logo-new.png";
import InstagramIcon from "../images/SocialMedia/instagram.png";
import FacebookIcon from "../images/SocialMedia/facebook.png";
import TwitterIcon from "../images/SocialMedia/twitter.png";

//how bluno works
import homePage1 from "../images/home-page-1.png";
import homePage2 from "../images/home-page-2.png";
import homePage3 from "../images/home-page-3.png";

//bluno security card
import security1 from "../images/security-1.png";
import security2 from "../images/security-2.png";
import security3 from "../images/security-3.png";

//demo
import requestDemo from "../images/demo-request.png";

export const DATA = {
  ABOUT_US: {
    title: "About Us",
    header: "Designed for Growth, Built by Experts",
    aboutinfo:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur Quis ipsum suspendisse ultrices gravida. Risus",
    gifUrl: { randomGif },
  },

  BANNER: {
    header: {
      heading1: "Export compliance made easy,",
      heading2: "just for you",
    },
    bannerText: {
      simpleText: "Say goodbye to",
      colouredText: [
        "complex compliance process",
        "paperwork hassles",
        "multiple bank visits",
        "bank charges & penalties",
        "delayed eBRC",
      ],
    },
    button: {
      name: "Check Pending Export Bills",
      btnType: "s-button--primary",
      link: "https://bluno/login",
      iconUrl: "https://some_icon_url_by strapi",
    },
    trusted: {
      text: "Powered by",
      icons: [
        {
          iconName: "banks",
          icon: yes,
        },
        // {
        //   iconName: "ca",
        //   icon: ca,
        // },
        // {
        //   iconName: "icici",
        //   icon: icici,
        // },
      ],
    },
  },

  ELEVATE: {
    title: "How Bluno works",
    heading: "Elevate your export processes with Bluno",
    timeline: [
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
    ],
  },

  EMPOWER: {
    title:
      "How Bluno <span class='empower--brand-text'> empowers </span> your business?",
    subtitle:
      "You focus on what truly matters—your exports, we handle the rest",
    cards: [
      {
        img: geteBRC,
        heading:
          "Get <span class='empower--brand-text'>eBRC instantly</span> for free",
        content:
          "Comply with the latest compliance requirements and Download eBRC on your system.",
        know_more: "know more",
        transitionDelay: 0.4,
      },
      {
        img: realTime,
        heading:
          "<span class='empower--brand-text'>Real-time tracking</span> of export bills & IRM",
        content:
          "Fully digital and transparent solution with automated and precise matching of transactions.",
        know_more: "know more",
        transitionDelay: 0.8,
      },
      {
        img: boostMargin,
        heading:
          "<span class='empower--brand-text'>Boost export margin</span> & productivity",
        content:
          "Save up to 4% of export turnover and upto 120 hours annually.",
        know_more: "know more",
        transitionDelay: 1.2,
      },
    ],
    button: {
      name: "Get Demo",
      btnType: "primary",
    },
  },

  FOOTER: {
    left: {
      title: {
        logo: blunologo,
        content: "bluno",
      },
      header: "Simplify your export, compete globally",
      address:
        "Hustlehub Tech Park, Somasundarapalya Main Road adjacent 27th Main Road, Sector 2, HSR Layout, Bengaluru 560102",
    },
    right: {
      button: {
        name: "Get Demo",
        btnType: "primary",
        link: "https://bluno/get-a-demo",
      },
      contactTitle: "Contact Us",
      contactEmail: "contact@blunoit.com",
      socialLinks: [
        {
          icon: InstagramIcon,
          altText: "Instagram",
          redirectionLink: "",
        },
        {
          icon: FacebookIcon,
          altText: "Facebook",
          redirectionLink: "",
        },
        {
          icon: TwitterIcon,
          altText: "Twitter",
          redirectionLink: "",
        },
      ],
    },
    copyright: {
      rights: "Copyright © 2024 Bluno. All rights reserved.",
      privacy: "Privacy Policy",
      partition: "|",
      terms: "Terms of Use",
    },
  },

  NAVBAR: {
    companyName: "bluno",
    contents: [
      {
        name: "Products",
        type: "list",
        list: [
          {
            name: "Product 1",
            link: "https:///link/product1",
          },
          {
            name: "Product 2",
            link: "https:///link/product2",
          },
          {
            name: "Product 3",
            link: "https:///link/product3",
          },
        ],
      },
      {
        name: "Use Cases",
        type: "list",
        list: [
          {
            name: "Use Case 1",
            link: "https:///link/usecase1",
          },
          {
            name: "Use Case 2",
            link: "https:///link/usecase2",
          },
          {
            name: "Use Case 3",
            link: "https:///link/usecase3",
          },
        ],
      },
      {
        name: "Blog",
        type: "link",
        link: "https://bluno/blog",
      },
      {
        name: "About",
        type: "link",
        link: "https://bluno/about",
      },
    ],
    button1Content: {
      name: "Get Demo",
      btnType: "primary",
      link: "https://bluno/get-a-demo",
    },
  },

  POWERED_BY: {
    title: "Powered by",
    imgLink: {
      img1: "img1Link",
      img2: "img2Link",
      img3: "img3Link",
      img4: "img4Link",
      img5: "img5Link",
    },
    secureData: "You data is 100% safe and secured by latest technology",
  },

  TESTIMONIAL: {
    title: "Testimonial",
    header: "What Bluno <span>users say</span>",
    slides: [
      {
        img: user1,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "primary",
      },
      {
        img: user2,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "secondary",
      },
      {
        img: user3,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "tertiary",
      },
      {
        img: user2,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "secondary",
      },
      {
        img: user1,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "primary",
      },
      {
        img: user3,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "tertiary",
      },
    ],
  },

  COMING_SOON: {
    left: {
      icon: BlunoIcon,
      title: "bluno",
      header: "Redefining the journey of",
      coloredHeader: "small & medium business exporters",
    },
    right: {
      title: "COMING SOON",
      header: "Join today for early access",
      inputs: {
        email: {
          label: "Email ID",
          placeHolder: "Enter your email ID",
        },
        phone: {
          label: "Mobile Number",
          countryCode: "+91",
          placeHolder: "Enter your mobile number",
        },
      },
      button: {
        name: "Get Early Access",
        btnType: "primary",
        link: "https://bluno/get-early-access",
      },
    },
  },

  THANK_YOU: {
    img: handshake,
    header: "Thank you for showing your interest!",
    content: "We will be in touch with you soon.",
    logoIcon: BlunoIcon,
    logoTitle: "bluno",
  },
};

export const HOW_BLUNO_WORKS = {
  sub_title: "How Bluno works ?",
  title:
    "Experience <span>smooth onboarding</span> & <span>easy navigation</span>",
  list: [
    {
      id: 1,
      text: "Fully digital onboarding process two easy steps",
      img_url: homePage1,
    },
    {
      id: 2,
      text: "Fetch real time export bills and IRM data",
      img_url: homePage2,
    },
    {
      id: 3,
      text: "Download eBRC instantly for free",
      img_url: homePage3,
    },
  ],
  button: {
    btnType: "primary",
    text: "Get Demo",
  },
};

export const BLUNO_SECURITY = [
  {
    img_url: security1,
    title: "Encryption",
    text: "Secure transmission of your data via SSL-certified pathways. We are VAPT tested and audited by CERT-In empanelled auditors.",
  },
  {
    img_url: security2,
    title: "Privacy",
    text: "Your data or your clients' data remains secure and will never be shared with unaffiliated third parties.",
  },
  {
    img_url: security3,
    title: "Two-factor authentication",
    text: "We enhance security by enabling 2-factor authentication, preventing. unauthorized access",
  },
];

export const DEMO_FORM = {
  title: "Fill in the details to <br><span>get a free demo</span>",
  form_data: [
    {
      id: 1,
      slug: "company_name",
      type: "input",
      sub_type: "text",
      label: "Company Name <span>*</span>",
      placeholder: "Company name",
      column: "1/3",
      row: "1",
    },
    {
      id: 2,
      slug: "mobile_number",
      type: "input",
      sub_type: "tel",
      label: "Mobile Number <span>*</span>",
      placeholder: "Mobile number",
      column: "1/3",
      row: "2",
    },
    {
      id: 3,
      slug: "email_address",
      type: "input",
      sub_type: "email",
      label: "Email ID (as per IEC) <span>*</span>",
      placeholder: "Email ID",
      column: "1/3",
      row: "3",
    },
    {
      id: 4,
      slug: "export_type",
      type: "checkbox",
      label: "Export Type <span>(optional)</span>",
      column: "1/3",
      row: "4",
      options: [
        {
          text: "Goods",
          slug: "good_and_merchandise",
          name: "export_type",
        },
        {
          text: "Services",
          slug: "services",
          name: "export_type",
        },
      ],
    },
    // {
    //   id: 5,
    //   slug: "export_turnover_currency",
    //   type: "dropdown",
    //   label: "Annual export turnover range<span> (optional)</span>",
    //   column: "1/3",
    //   row: "4",
    //   column_mobile: "1/3",
    //   row_mobile: "5",
    //   options: [
    //     { label: "INR", value: "inr" },
    //     { label: "USD", value: "usd" },
    //     ,
    //   ],
    // },
    // {
    //   id: 6,
    //   slug: "export_turnover_range",
    //   type: "slider",
    //   column: "1/3",
    //   row: "5",
    //   column_mobile: "1/3",
    //   row_mobile: "6",
    //   step: 1,
    //   min: 0,
    //   max: 500,
    //   marks: [
    //     {
    //       value: 2,
    //       text1: "Upto 1",
    //       text2: "crore",
    //     },
    //     {
    //       value: 100,
    //       text1: "5",
    //       text2: "crore",
    //     },
    //     {
    //       value: 200,
    //       text1: "25",
    //       text2: "crore",
    //     },
    //     {
    //       value: 300,
    //       text1: "50",
    //       text2: "crore",
    //     },
    //     {
    //       value: 400,
    //       text1: "100",
    //       text2: "crore",
    //     },
    //     {
    //       value: 493,
    //       text1: "Above",
    //       text2: "500 crore",
    //     },
    //   ],
    //   marksUSD: [
    //     {
    //       value: 2,
    //       text1: "Upto 100",
    //       text2: "thousand",
    //     },
    //     {
    //       value: 102,
    //       text1: "500",
    //       text2: "thousand",
    //     },
    //     {
    //       value: 200,
    //       text1: "1",
    //       text2: "million",
    //     },
    //     {
    //       value: 300,
    //       text1: "5",
    //       text2: "million",
    //     },
    //     {
    //       value: 400,
    //       text1: "25",
    //       text2: "million",
    //     },
    //     {
    //       value: 493,
    //       text1: "Above 50",
    //       text2: "million",
    //     },
    //   ],
    // },
    {
      id: 5,
      slug: "agreement",
      column: "1/3",
      row: "5",
      type: "text",
      text: 'By continuing you agree to our <a href="/privacy-policy" target="_parent">privacy policy</a> and <a href="/terms-of-service" target="_parent">terms of service</a>',
    },
    {
      id: 7,
      slug: "request_demo",
      column: "1/3",
      row: "6",
      type: "button",
      button_label: "Book Demo",
      button_type: "primary",
      icon: requestDemo,
      iconAlignment: "left",
    },
  ],
};
