import React from 'react'

const SearchIcon = (props) => {
  const { color = "#000000" } = props;

  return (
    <>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4 13.4498L9.5 10.5498"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.83333 11.2165C8.77885 11.2165 11.1667 8.82866 11.1667 5.88314C11.1667 2.93762 8.77885 0.549805 5.83333 0.549805C2.88781 0.549805 0.5 2.93762 0.5 5.88314C0.5 8.82866 2.88781 11.2165 5.83333 11.2165Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}

export default SearchIcon
