// react imports
import React, { useState } from "react";

// third-party imports

// in-app imports
import BlunoModal from "./BlunoModal";
import DemoClone from "./DemoClone";
import DemoCompleteClone from "./DemoClone/DemoCompleteClone";
import DemoVideo from "./DemoVideo";

export default function Layout({ children }) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalClone, setOpenModalClone] = useState(true);
  const [openModalVideo, setOpenModalVideo] = useState(false);
  const [screen, setScreen] = useState(1);
  const [screenClone, setScreenClone] = useState(1);
  const [videoId, setVideoId] = useState("");

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        openModal,
        setOpenModal,
        openModalClone,
        setOpenModalClone,
        screen,
        setScreen,
        screenClone,
        setScreenClone,
        openModalVideo,
        setOpenModalVideo,
        setVideoId,
      });
    }
    return child;
  });

  return (
    <div className="layout">
      <BlunoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        screen={screen}
        setScreen={setScreen}
        closeOutside={screen === 1 ? false : true}
      >
        {screen === 1 ? (
          <DemoClone flowType="home_page" />
        ) : (
          <DemoCompleteClone flowType="home_page" />
        )}
      </BlunoModal>
      <BlunoModal openModal={openModalVideo} setOpenModal={setOpenModalVideo}>
        <DemoVideo videoId={videoId} />
      </BlunoModal>
      {childrenWithProps}
    </div>
  );
}
