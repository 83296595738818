import React, { forwardRef, useEffect, useState } from "react";
import InputErrorMsg from "./InputErrorMsg";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "../images/svg/SearchIcon";

const TextInput = forwardRef(
  (
    {
      placeholder = "Enter Text",
      type = "text",
      icon = "",
      iconClass = "",
      iconAlignment = "left",
      height = "48px",
      minWidth = "100px",
      onChange,
      onKeyDown,
      onBlur = () => {},
      value = "",
      isHighlighted,
      id,
      minLength,
      maxLength,
      extraClass,
      disabled = false,
      errorMessage,
      errorIcon,
      errorIconAlignment,
      fixedText = "",
      paddingLeft = null,
      showMuiInput = false,
      searchIcon = true,
      readonly = false,
      isFocusedExternal = null,
      cursor = "text",
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
      if (isFocusedExternal === null) setIsFocused(true);
    };
    const handleBlur = (e) => {
      if (isFocusedExternal === null) setIsFocused(false);
      onBlur(e);
    };
    const inputStyles = {
      height: height,
      minWidth: minWidth,
    };

    const inputBoxStyles = {
      paddingLeft: paddingLeft,
    };

    useEffect(() => {
      setIsFocused(isFocusedExternal);
    }, [isFocusedExternal]);

    return (
      <>
        {!showMuiInput ? (
          <div className={`s-textinput`} style={inputStyles}>
            {icon && (
              <img
                className={`s-textinput__icon s-textinput__icon--${iconAlignment}`}
                src={icon}
              />
            )}
            {fixedText && (
              <p
                className={`s-textinput__fixed ${
                  disabled ? "s-textinput__input--disabled" : ""
                }`}
              >
                {fixedText}
              </p>
            )}
            <input
              className={`s-textinput__input s-textinput__input--${iconAlignment} ${
                errorMessage && `s-textinput__input--error`
              }  ${
                (isHighlighted ? "s-textinput__input--highlighted" : "",
                disabled ? "s-textinput__input--disabled" : "")
              } ${extraClass}`}
              style={inputBoxStyles}
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              id={id}
              minLength={minLength}
              maxLength={maxLength}
              onKeyDown={onKeyDown}
              ref={ref}
              disabled={disabled}
              onBlur={onBlur}
            />
            {errorMessage && (
              <InputErrorMsg
                message={errorMessage}
                icon={errorIcon}
                iconAlignment={errorIconAlignment}
              />
            )}
          </div>
        ) : (
          <div className={`s-textinput ${extraClass}`}>
            {fixedText && <p className="s-textinput__fixed">{fixedText}</p>}
            <TextField
              variant="outlined"
              type={type}
              label={placeholder}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              id={id}
              focused={isFocused}
              inputProps={{
                readOnly: readonly,
                minLength: minLength,
                maxLength: maxLength,
                style: {
                  height: "20px",
                  // padding: "8px 5px",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {iconAlignment === "right" && icon != null && (
                      <InputAdornment
                        position="end"
                        sx={{ marginLeft: "-5px" }}
                      >
                        <img className={iconClass} src={icon} />
                      </InputAdornment>
                    )}
                  </>
                ),
                startAdornment: (
                  <>
                    {iconAlignment === "left" && searchIcon && (
                      <InputAdornment
                        position="start"
                        sx={{ marginLeft: "-5px" }}
                      >
                        <SearchIcon
                          color={
                            isFocused || isHighlighted ? "#046cb8" : "black"
                          }
                        />
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              onKeyDown={onKeyDown}
              inputRef={ref}
              disabled={disabled}
              error={!!errorMessage}
              helperText={
                <InputErrorMsg
                  message={errorMessage}
                  icon={errorIcon}
                  iconAlignment={errorIconAlignment}
                />
              }
              sx={{
                "& .MuiInputBase-root": {
                  height: height,
                  minWidth: minWidth,
                  paddingLeft: paddingLeft,
                  borderRadius: "8px",
                  bgcolor: "#fff",
                  cursor: cursor,
                },

                "& .MuiInputLabel-root": {
                  fontSize: "13px",
                  top: "-6px",
                  left: iconAlignment === "left" ? "13px" : "",
                  paddingLeft: fixedText ? "34px" : "",
                  "&.Mui-focused": {
                    transform: " translate(10px, 3px) scale(0.85)", // Adjust label transformation when focused
                    left: iconAlignment === "left" ? "" : "5px",
                    color: "#000000",
                    paddingLeft: fixedText ? "0px" : "",
                  },
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(10px, 3px) scale(0.85)", // Adjust label transformation when not focused but with value
                    left: iconAlignment === "left" ? "" : "5px",
                    top: "-11px",
                    padding: "0px",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  color: isHighlighted ? "#046cb8" : "",
                  paddingLeft: fixedText ? "42px" : "",
                  "& fieldset": {
                    borderColor: isHighlighted ? "#046cb8" : "",
                  },
                  "&:hover fieldset": {
                    borderColor: isHighlighted ? "#046cb8" : "",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isHighlighted ? "#046cb8" : "",
                  },
                },
                ".MuiFormHelperText-root": {
                  margin: "0px 0px 5px 0px",
                },

                input: { cursor: cursor },
                cursor: cursor,
              }}
              InputLabelProps={{
                shrink: isFocused || Boolean(value),
              }}
              className={`${errorMessage && `s-textinput__input--error`}  ${
                (isHighlighted ? "s-textinput__input--highlighted" : "",
                disabled ? "s-textinput__input--disabled" : "")
              }
            
               ${extraClass}`}
              fullWidth
            />
          </div>
        )}
      </>
    );
  }
);

export default TextInput;
