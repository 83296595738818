// react imports
import React from "react";

// third party imports
import Select from "react-select";

// in-app imports

const Dropdown = ({
  options = [
    {
      label: "Option 1",
      value: "option_1",
    },
    {
      label: "Option 2",
      value: "option_2",
    },
  ],
  onSelectChange,
  name,
  isRequired = false,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  isLoading = false,
  isRtl = false,
  defaultValue = null,
  placeholder,
  maxMenuHeight,
  fontWeight = "500",
  fontSize = "15px",
  padding = "5px 10px",
  valuePadding = "2px 8px",
  indicatorPadding = "8px",
  minHeight = "38px",
}) => {
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #E7EAEE",
      // This line disable the blue border
      boxShadow: "none",
      borderRadius: "8px",
      fontSize: fontSize,
      fontWeight: fontWeight,
      padding: padding,
      minHeight: minHeight,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
      padding: indicatorPadding,
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#EBF4FF" : "#FFFFFF",
        color: isFocused ? "#1F1F1F" : "#888888",
        fontSize: "15px",
      };
    },
    menu: (base) => {
      return {
        ...base,
        padding: "10px 5px",
        borderRadius: "8px",
        border: "1px #E7EAEE solid",
      };
    },
    singleValue: (base) => {
      return {
        ...base,
        color: "#191D23",
      };
    },
    valueContainer: (base) => {
      return {
        ...base,
        padding: valuePadding,
      };
    },
    indicatorsContainer: (base) => {
      return {
        ...base,
        height: "100%",
        alignSelf: "",
      };
    },
  };

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      value={options?.filter((option) => option?.value === defaultValue)}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      isSearchable={isSearchable}
      name={name}
      required={isRequired}
      options={options}
      placeholder={placeholder}
      onChange={onSelectChange}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={style}
      maxMenuHeight={maxMenuHeight}
    />
  );
};

export default Dropdown;
