//react imports
import React, { useEffect, useRef, useState } from "react";

//external library imports
import YouTubePlayer from "youtube-player";
import Slider from "@mui/material/Slider";

//images
import play from "../images/Play.png";
import pause from "../images/Pause.png";
import forward from "../images/Forward.png";
import back from "../images/Back.png";
import volume from "../images/Volume.png";
import mute from "../images/Mute.png";
import setting from "../images/Setting.png";
import fullscreen from "../images/Full Screen.png";

export default function DemoVideo({ videoId }) {
  //local states
  const [paused, setPaused] = useState(true);
  const [muted, setMuted] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [volumeValue, setVolumeValue] = useState(50);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [videoLength, setVideoLength] = useState("00:00");

  //local refs
  const playerRef = useRef(null);
  const iframeRef = useRef(null);
  const firstload = useRef(false);

  const converTimeToString = (timerValue) => {
    return (
      (~~(timerValue / 60) >= 10
        ? ~~(timerValue / 60)
        : "0" + ~~(timerValue / 60)) +
      ":" +
      (timerValue % 60 >= 10 ? ~~(timerValue % 60) : "0" + ~~(timerValue % 60))
    );
  };

  const handlePlay = async () => {
    if (playerRef.current) {
      playerRef.current.playVideo();
      setPaused(false);
    }
  };

  const handlePause = () => {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
      setPaused(true);
    }
  };

  const handleVolume = (val) => {
    if (playerRef.current) {
      playerRef.current.setVolume(val);
    }
  };

  const handleMute = () => {
    if (playerRef.current) {
      playerRef.current.mute();
    }
  };

  const handleUnMute = () => {
    if (playerRef.current) {
      playerRef.current.unMute();
      handleVolume(volumeValue);
    }
  };

  const handleFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) {
        // Firefox
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) {
        // IE/Edge
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  useEffect(async () => {
    const player = YouTubePlayer(iframeRef.current, {
      videoId: videoId,
      height: "511",
      width: "908",
      playerVars: {
        autoplay: 0,
        controls: 0, // Disable YouTube's default controls
        modestbranding: 1,
        rel: 0,
        fs: 1, //allow fullscreen
        iv_load_policy: 3,
        disablekb: 1, //disable keybaord input
      },
    });

    player.on("stateChange", async (e) => {
      switch (e.data) {
        case 2: //player paused
          setPaused(true);
          break;
        case 1:
          setPaused(false);
          if (!firstload.current) {
            firstload.current = true;
            setVideoLength(converTimeToString(await player.getDuration()));
          }
          break;
        default:
          break;
      }
    });

    // Attach player instance to ref for external control
    playerRef.current = player;
    iframeRef.current = await player.getIframe();
    player.setVolume(50);
    const elapsedTimeInterval = setInterval(async () => {
      setTimeElapsed(await player.getCurrentTime());
    }, 500);

    return () => {
      clearInterval(elapsedTimeInterval);
      player.destroy();
    };
  }, []);

  return (
    <div className="player-wrap">
      <div className="player" ref={iframeRef}></div>
      <div className="player__controls">
        <div className="player__controls-container">
          <img src={back} className="player__controls-image" />
          <img
            onClick={paused ? () => handlePlay() : () => handlePause()}
            src={paused ? play : pause}
            className="player__controls-image"
          />
          <img src={forward} className="player__controls-image" />
          <div
            className="player__controls-volume"
            onMouseEnter={() => {
              setShowVolume(true);
            }}
            onMouseLeave={() => setShowVolume(false)}
          >
            {showVolume && (
              <div className="player__controls-volume-slider">
                <Slider
                  sx={{
                    '& input[type="range"]': {
                      WebkitAppearance: "slider-vertical",
                    },
                  }}
                  orientation="vertical"
                  onKeyDown={preventHorizontalKeyboardNavigation}
                  value={volumeValue}
                  onChange={(e, val) => {
                    handleVolume(val);
                    setVolumeValue(val);
                  }}
                  style={{ height: "70px" }}
                  disabled={muted}
                />
              </div>
            )}
            <img
              src={muted ? mute : volume}
              className="player__controls-image"
              onClick={() =>
                setMuted((prev) => {
                  if (prev) handleUnMute();
                  else handleMute();
                  return !prev;
                })
              }
            />
          </div>
        </div>
        <div className="player__controls-container player__controls-container-v2">
          <p className="player__controls-time">
            {converTimeToString(timeElapsed)} / {videoLength}
          </p>
          <img src={setting} className="player__controls-image" />
          <img
            src={fullscreen}
            className="player__controls-fullscreen"
            onClick={() => {
              handleFullscreen();
            }}
          />
        </div>
      </div>
    </div>
  );
}
